import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from "prop-types";

import Container from 'components/ui/Container';
import * as Styled from './styles';
import { setPassword } from "../../state/user/user.service";
import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import Danger from "../ui/Alert/Danger";
import GoBackLink from "../ui/GoBackLink";

const SetPassword = ({ token, locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiSetPasswordPage_en: strapiSetPasswordPage(locale: {eq: "en"}) {
        create_your_password
        confirm_password
        submit
      }
      strapiSetPasswordPage_zh: strapiSetPasswordPage(locale: {eq: "zh"}) {
        create_your_password
        confirm_password
        submit
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: {eq: "en"}) {
        invalid_password
        password_required
        weak_password
        confirm_password_required
        confirm_password_not_matching
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: {eq: "zh"}) {
        invalid_password
        password_required
        weak_password
        confirm_password_required
        confirm_password_not_matching
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
    }
  `);
  const translations = gql[`strapiSetPasswordPage_${ locale }`];
  const errTranslations = gql[`strapiErrorMessages_${ locale }`];
  const [serverErrors, setErrors] = useState([]);
  const { navigate } = useI18next();
  return (
    <Container section>
      <Styled.Wrapper>
        <Styled.Mob>
          <Styled.MobileTtitle>{ translations.create_your_password }</Styled.MobileTtitle>
        </Styled.Mob>
        <br/>
        <Formik
          initialValues={ { password: '', confirmedPassword: '' } }
          validate={ (values) => {
            const errors = {};
            if (!values.password) {
              errors.password = errTranslations.password_required;
            } else if (!values.password.match(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g)) {
              errors.password = errTranslations.weak_password;
            }

            if (!values.confirmedPassword) {
              errors.confirmedPassword = errTranslations.confirm_password_required;
            } else if (values.confirmedPassword && values.password && (values.confirmedPassword !== values.password)) {
              errors.confirmedPassword = errTranslations.confirm_password_not_matching;
            }

            return errors;
          } }
          onSubmit={ async (values) => {
            const setPasswordResponse = await setPassword(values.password, values.confirmedPassword, token)
              .toPromise()
              .catch((ajax) => {
                switch (ajax.status) {
                  case 500:
                    setErrors([errTranslations.server_error_500]);
                    break;
                  case 502:
                    setErrors([errTranslations.server_error_502]);
                    break;
                  case 400:
                    setErrors([errTranslations.request_error_422]);
                    break;
                  case 401:
                    setErrors([errTranslations.invalid_login_credentials]);
                    break;
                  case 409:
                    setErrors(['Confirm Email Token Expired. Please Signup Again.']);
                    break;
                  case 422:
                    setErrors([errTranslations.request_error_422]);
                    break;
                  default:
                    setErrors([errTranslations.unknown_error]);
                    break;
                }
              });

            await navigate(`/process-login/?accessToken=${ setPasswordResponse.accessToken }`)
          } }
        >
          { (formikProps) => {
            return (
              <Form>
                <Styled.Text small>{translations.create_your_password}</Styled.Text>
                <Field type="password" name="password">
                  {({ field, meta: { touched, error } }) => (
                    <input
                      className={touched && error ? 'bg-red-100 border border-red-100' : ''}
                      type="password"
                      {...field}
                    />
                  )}
                </Field>
                <ErrorMessage name="password" component="div" className="mt-1 text-xs text-red-600" />
                <Styled.Text small>{translations.confirm_password}</Styled.Text>
                <Field type="password" name="confirmedPassword">
                  {({ field, meta: { touched, error } }) => (
                    <input
                      className={touched && error ? 'bg-red-100 border border-red-100' : ''}
                      type="password"
                      {...field}
                    />
                  )}
                </Field>

                <ErrorMessage name="confirmedPassword" component="div" className="mt-1 text-xs text-red-600" />

                <Styled.Button type="submit" disabled={formikProps.isSubmitting} className={formikProps.isValid && formikProps.dirty ? "enabledBtn text-white" : "bg-gray-200 w-full text-gray-500"}>
                  {translations.submit}
                </Styled.Button>

                {serverErrors.map((err) => <Danger message={err} />)}
              </Form>
            )
          } }
        </Formik>
        <br/>
        <GoBackLink title={locale === 'en' ? 'Go Back' : '后退'} link='/login'/>
      </Styled.Wrapper>
    </Container>
  );
};

SetPassword.propTypes = {
  locale: PropTypes.string,
};

SetPassword.defaultProps = {
  locale: 'en',
};

export default SetPassword;
