import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SetPassword from '../components/SetPassword';
import { getQueryVariable } from "../state/utils";
import { I18nextContext } from "gatsby-plugin-react-i18next";

const Password: React.FC = (props: any) => {
  const { language } = React.useContext(I18nextContext);
  const token = getQueryVariable('token', props.location)
  return (
    <Layout page='set-password' locale={language}>
        <SEO title="Set Password" />
        <SetPassword token={token} locale={language}/>
    </Layout>
  );
};

export default Password;
