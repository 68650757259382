import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`bg-white w-full sm:w-6/12 md:w-4/12 h-auto mx-auto py-8 px-8 flex flex-col `};
  a{
    ${tw`text-blue-500`};
  }
`;

export const Text = styled.p`
  ${tw`text-sm text-brown-darker m-0`};
  ${({ bold }) => (bold ? tw`font-bold text-base text-brown-semidark` : tw``)};
  ${({ center }) => (center ? tw`my-4 mx-auto text-center` : tw``)};
  ${({ left }) => (left ? tw`flex-auto text-xs text-left` : tw``)};
  ${({ right }) => (right ? tw`flex-auto text-xs text-right` : tw``)};
  ${({ small }) => (small ? tw`text-xs  font-semibold` : tw``)};
  ${({ centermb }) => (centermb ? tw`mb-2 mx-auto text-center` : tw``)};
`;

export const Button = styled.button`
  ${tw`flex mx-auto justify-center text-base mt-2 font-normal tracking-wider gap-2 py-3 px-8 `};
    ${({ fb }) => (fb ? tw`bg-blue-500 text-white w-full sm:w-8/12` : tw``)};
    ${({ google }) => (google ? tw`bg-white border shadow-md w-full sm:w-8/12` : tw``)};
    ${({ apple }) => (apple ? tw`bg-black text-white w-full sm:w-8/12` : tw``)};
`;

export const ButtonLink = styled.button`
  ${tw`text-xs text-blue-600`};
`;

export const Notes = styled.div`
  margin-top: 1rem;
  background: #FFFAF5;
  ${tw`text-sm w-full border border-yellowu-basic rounded p-3 mb-4`};
  label{
    color: #425567;
    letter-spacing: 0.4px;
    ${tw`text-xssemi`}
  };
  p{
    color: #656B70;
    letter-spacing: 0.8px;
    ${tw`text-xs font-normal`}
  };
`;

export const Mob = styled.div`
  ${tw`block sm:hidden`};
`;

export const Desk = styled.div`
  ${tw`hidden sm:block`};
`;

export const MobileTtitle = styled.p`
  ${tw`text-xl2 text-center text-grayu-basic font-bold`};
`;

export const MobileText = styled.p`
  ${tw`mb-6 text-sm text-center text-brown-darker`};
`;
